<template>
    <div id="page-wrapper">
        <top-header></top-header>
        <side-navbar></side-navbar>
        <main-content title="Volume Summary">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title" v-if="$store.getters.isFuturesAccount()">Contracts traded per ${{scaleFactor | numeralFormat('0.0a')}} AUM</h4>
                            <h4 class="card-title" v-else-if="$store.getters.isFxAccount()">Volume traded per ${{scaleFactor | numeralFormat('0.0a')}} AUM</h4>
                        </div>
                        <div class="card-body">
                            <volume @scaleFactorChange="onScaleFactorChange"></volume>
                        </div>
                    </div>
                </div>
            </div>
        </main-content>
    </div>
</template>

<script>
import TopHeader from '@/components/TopHeader';
import SideNavbar from '@/components/SideNavbar';
import MainContent from '@/components/MainContent';
import Volume from '@/widgets/Volume';

export default {
    data() {
        return {
            scaleFactor: '$10,000,000'
        };
    },
    components: {
        TopHeader,
        SideNavbar,
        MainContent,
        Volume
    },
    methods: {
        onScaleFactorChange(scale) {
            this.scaleFactor = scale;
        }
    }
}
</script>