<template>
    <div class="volume-widget">
        <div class="row mb-3">
            <div class="col-12 d-flex align-items-center justify-content-end">
                <b-button variant="light" class="mr-auto" @click="onExport">
                    Export
                </b-button>
                <b-form inline @submit.prevent>
                    <label class="mr-sm-2" for="volume-type-filter">Type:</label>
                    <b-form-select id="volume-type-filter" class="mb-2 mr-sm-2 mb-sm-0" v-model="type" :options="filterOptions.type" @change="requests"></b-form-select>
                    <label class="mr-sm-2" for="volume-time-period">Time Period:</label>
                    <b-form-select id="volume-time-period" class="mb-2 mr-sm-2 mb-sm-0" v-model="timePeriod" :options="filterOptions.timePeriod" @change="requests"></b-form-select>
                    <label class="mr-sm-2" for="volume-time-period">Scale Factor:</label>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" v-model="scaleFactor" @blur="onScaleFactorChange" :formatter="formatScaleFactor"></b-form-input>
                </b-form>
            </div>
        </div>
        <b-table :fields="tableOptions.fields" :items="tableOptions.items" responsive id="volume-table" :per-page="50" :current-page="currentPage">
            <template slot="bottom-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                    <template v-if="field.key === 'date'">Average</template>
                    <template v-else>{{getAverage(field.key)}}</template>
                </td>
            </template>
        </b-table>
        <div class="row mt-3">
            <div class="col-12 d-flex align-items-center justify-content-start">
                <b-pagination v-model="currentPage" :total-rows="tableOptions.items.length" :per-page="50" aria-controls="volume-table"></b-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { format, endOfMonth } from 'date-fns';
import { map, clone } from 'lodash';
import { exportCsv } from '@/lib/Exporting';

export default {
    name: 'volume',
    data() {
        return {
            type: 'Market',
            timePeriod: 'Monthly',
            scaleFactor: `$${this.$options.filters.number(1000000, 0)}`,
            currentPage: 1,
            filterOptions: {
                type: [
                    {
                        value: 'Market',
                        text: 'Market'
                    },
                    {
                        value: 'Factor',
                        text: 'Factor'
                    },
                    {
                        value: 'Bucket',
                        text: 'Bucket'
                    }
                ],
                timePeriod: [
                    {
                        value: 'Daily',
                        text: 'Daily'
                    },
                    {
                        value: 'Weekly',
                        text: 'Weekly'
                    },
                    {
                        value: 'Monthly',
                        text: 'Monthly'
                    },
                    {
                        value: 'Annually',
                        text: 'Annually'
                    }
                ]
            },
            tableOptions: {
                fields: [],
                items: []
            },
            hasSetupListeners: false,
            fxAccount: false
        };
    },
    mounted() {
        this.requests();
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('volume', this.onVolume);
                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            let scaleFactor = this.scaleFactor;
            scaleFactor = scaleFactor.replace(/\$/g, '');
            scaleFactor = scaleFactor.replace(/,/g, '');

            if(this.$store.getters.isCombinedAccount(account)) {
                return;
            }

            this.fxAccount = this.$store.getters.isFxAccount(account);

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'Volume',
                args: {
                    timePeriod: this.timePeriod,
                    filterType: this.type,
                    scaleFactor: scaleFactor
                }
            });
        },
        onVolume(event) {
            let columns = [];

            columns.push({
                key: 'date',
                label: 'Date',
                sortable: true,
                formatter: function(value) {
                    return format(value, 'dd/MM/yyyy');
                }
            });

            let that = this;

            for(let key in event.response.totals) {
                columns.push({
                    key: key,
                    label: key,
                    sortable: true,
                    formatter: function(value) {
                        if(parseFloat(value) === 0) {
                            return '-';
                        }

                        if(that.fxAccount) {
                            return that.$options.filters.numeralFormat(value, '0.0a');
                        }

                        return that.$options.filters.number(value, 0);
                    }
                });
            }

            columns.push({
                key: 'total',
                label: 'Total',
                sortable: true,
                formatter: function(value) {
                    if(parseFloat(value) === 0) {
                        return '-';
                    }

                    if(that.fxAccount) {
                        return that.$options.filters.numeralFormat(value, '0.0a');
                    }

                    return that.$options.filters.number(value, 0);
                }
            })

            let rows = [];

            for(let date in event.response.data) {
                if(!this.fxAccount && date.indexOf('-') !== -1) {
                    let split = date.split('-');

                    if(split.length >= 2 && split[0] === '2018' && split[1] === '07') {
                        continue;
                    }
                }

                let row = {
                    date: this.timePeriod === 'Monthly' ? endOfMonth(new Date(date)) : new Date(date)
                };

                let total = 0;
                for(let key in event.response.data[date]) {
                    row[key] = event.response.data[date][key];
                    total += event.response.data[date][key];
                }

                row.total = total;

                rows.push(row);
            }

            this.tableOptions.fields = columns;
            this.tableOptions.items = rows;
        },
        formatScaleFactor(value) {
            let scaleFactor = value;
            scaleFactor = scaleFactor.replace(/\$/g, '');
            scaleFactor = scaleFactor.replace(/,/g, '');

            return `$${this.$options.filters.number(scaleFactor, 0)}`;
        },
        onExport() {
            let headers = {};
            for(let header of this.tableOptions.fields) {
                headers[header.key] = header.label;
            }

            let rows = map(this.tableOptions.items, clone);
            for(let row of rows) {
                row.date = format(row.date, 'yyyy-MM-dd');
            }

            let averageRow = {
                date: 'Average'
            };

            for(let header in headers) {
                if(header === 'date') {
                    continue;
                }

                let sum = 0;
                let length = 0;
                for(let row of rows) {
                    sum += row[header];
                    length++;
                }

                averageRow[header] = sum / length;

                if(!this.fxAccount) {
                    averageRow[header] = Math.round(averageRow[header]);
                }
            }

            rows.push(averageRow);

            exportCsv(headers, rows, 'VolumeSummary');
        },
        onScaleFactorChange() {
            this.requests();
            let scaleFactor = this.scaleFactor;
            scaleFactor = scaleFactor.replace(/\$/g, '');
            scaleFactor = scaleFactor.replace(/,/g, '');

            this.$emit('scaleFactorChange', scaleFactor);
        },
        getAverage(field) {
            let sum = 0;
            let length = 0;
            for(let item of this.tableOptions.items) {
                sum += parseFloat(item[field]);
                length++;
            }

            let average = sum / length;

            if(this.fxAccount) {
                return this.$options.filters.numeralFormat(average, '0.0a');
            }

            return this.$options.filters.number(average, 0);
        }
    }
}
</script>

<style lang="scss">
#volume-table {
    th, td {
        text-align: center;
    }
}
</style>